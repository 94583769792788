window.addEventListener('DOMContentLoaded', () => {
  // User agent
  const ua = navigator.userAgent;
  const isMobile = ua.indexOf('iPhone') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0 || ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0;

  // for lazy load
  let lazyLoadImages = document.querySelectorAll('.lozad');
  if (lazyLoadImages.length > 0) {
    lazyLoadImages = Array.prototype.slice.call(lazyLoadImages, 0);
    lazyLoadImages.forEach(image => {
      if (image.classList.contains('responsive')) {
        const {
          spBackgroundImage,
          pcBackgroundImage,
          spSrc,
          pcSrc
        } = image.dataset;
        if (isMobile) {
          if (spBackgroundImage) {
            image.dataset.backgroundImage = spBackgroundImage;
          }
          if (spSrc) {
            image.dataset.src = spSrc;
          }

        } else { // PC
          if (pcBackgroundImage) {
            image.dataset.backgroundImage = pcBackgroundImage;
          }
          if (pcSrc) {
            image.dataset.src = pcSrc;
          }

        }
      }
    });

    let observer = lozad();
    observer.observe();
  }

  // Menu
  const menuButton = document.querySelector('.fn-menu-button');
  const header = document.querySelector('.fn-header');
  if (menuButton && header) {
    menuButton.addEventListener('click', (clickedButton) => {
      header.classList.toggle('is-open');
      if (header.classList.contains('is-open')) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    });
  }

  // Scroll functoin
  inView('.fn-animate').on('enter', (el) => {
    if (!el.classList.contains('animated')) {
      const animateProperty = el.dataset.animate;
      el.classList.add('animated');
      if (animateProperty) {
        el.classList.add(animateProperty);
      }
    }
  });

  // for smooth scroll. Using the jquery.
  $(function(){
    $('a[href^="#"]').click(function(){
      const speed = 500;
      const href= $(this).attr("href");
      const target = $(href === "#" || href === "" ? 'html' : href);
      const position = target.offset().top;
      $("html, body").stop().animate({scrollTop:position}, speed, "swing", () => {
        window.location = href;
        return false;
      });
    });

    const CONTACT_API_URL = 'https://work-canvas.com/api/inquires';

    $(".c-button input").bind("click", function(event) {
      event.preventDefault();

      const contactForm = $(".c-form"),
        contactName = contactForm.find("#contact-name"),
        contactEmail = contactForm.find("#contact-email"),
        contactTel = contactForm.find("#contact-tel"),
        contactDetail = contactForm.find("#contact-detail"),
        contactAcceptance = contactForm.find("#acceptance");

      let validationError = false;

      $([contactName, contactEmail, contactTel, contactDetail]).each(function(index, elem){
        const $elem = $(elem);
        if ($elem.val()) {
          if ($elem.closest(".field").hasClass("error")) {
            $elem.closest(".field").removeClass("error");
          }
        } else {
          if (!$elem.closest(".field").hasClass("error")) {
            $elem.closest(".field").addClass("error");
          }
          validationError = true;
        }
      });

      if (validationError || !contactAcceptance.is(":checked")) {
        return false;
      }

      const params = {
        name: contactName.val(),
        email: contactEmail.val(),
        tel: contactTel.val(),
        content: contactDetail.val()
      }

      $.ajax({
        url: CONTACT_API_URL,
        type: 'POST',
        data: params,
        dataType: 'json',
        success: function(res) {
          if (res.status && res.status === 'success') {
            contactForm.find("#contact-name, #contact-email, #contact-tel, #contact-detail").each(function() {
              $(this).val("");
            });
            contactForm.find("#acceptance").removeAttr('checked').prop("checked", false);
          }
        },
        error: function (res) {
          const response = $.parseJSON(res.responseText);
          if (response.data) {
            if (response.data.match("Eメール")) {
              const targetForm = $(".c-form #contact-email"),
                targetFormField = targetForm.closest(".field");
              targetFormField.addClass("error");
            }
            if (response.data.match("電話番号")) {
              const targetForm = $(".c-form #contact-tel"),
                targetFormField = targetForm.closest(".field");
              targetFormField.addClass("error");
            }
          }
        }
      });
    });
  });
});
